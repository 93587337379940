import React, { FC } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImage } from "gatsby-plugin-storyblok-image";
import classNames from "classnames";
import { PostSummary } from "@app/models";
import { useFormatDate } from "@app/hooks";
import { CategoryLabel } from "@app/components";
import { Link } from "..";

interface PostCardProps extends PostSummary {
  variant?: "row" | "column";
}

export const PostCard: FC<PostCardProps> = ({
  variant = "column",
  ...rest
}) => {
  switch (variant) {
    case "column":
      return <PostCardColumn {...rest} />;
    case "row":
      return <PostCardRow {...rest} />;
    default:
      return null;
  }
};

const PostCardColumn: FC<PostCardProps> = ({
  image,
  date,
  title,
  categoryId,
  slug,
}) => {
  const formattedDate = useFormatDate(date);

  return (
    <div>
      <div className="mb-7.5">
        <GatsbyImage
          image={getGatsbyImage(image, { layout: "fullWidth" })}
          alt={title}
          className={"aspect-[3/2]"}
        />
      </div>
      <div>
        <div className={classNames("mb-2.5 text-regular text-base-400")}>
          {formattedDate}
        </div>
        <Link to={`/blog/${categoryId}/${slug}`}>
          <h4 className="text-medium-low text-primary-content">{title}</h4>
        </Link>
        {categoryId && (
          <div className="mt-5">
            <CategoryLabel categoryId={categoryId} />
          </div>
        )}
      </div>
    </div>
  );
};

const PostCardRow: FC<PostCardProps> = ({
  image,
  date,
  title,
  categoryId,
  excerpt,
  slug,
}) => {
  const formattedDate = useFormatDate(date);

  return (
    <div className="md:grid md:grid-cols-10 md:gap-12">
      <div className="mb-7.5 md:col-span-5 md:mb-0 xl:col-span-6">
        <GatsbyImage
          image={getGatsbyImage(image, { layout: "fullWidth" })}
          alt={title}
          className="aspect-[3/2]"
        />
      </div>
      <div className="col-span-full flex flex-col gap-5 md:col-span-5 xl:col-span-4">
        <div className="text-regular text-base-400">{formattedDate}</div>
        <Link to={`/blog/${categoryId}/${slug}`} className="text-blue-500">
          <h3 className="text-medium-low text-primary-content xl:text-large">
            {title}
          </h3>
        </Link>
        {excerpt && <p className="text-regular text-base-400">{excerpt}</p>}
        <div>
          <CategoryLabel categoryId={categoryId} />
        </div>
      </div>
    </div>
  );
};
