import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import dayjs from "dayjs";
import { EventPage, PostSummary } from "@app/models";
import { filterStoryblokCollectionByLanguage } from "@app/helpers";

export const useEventsPosts = () => {
  const { language } = useI18next();
  const result = useStaticQuery(graphql`
    {
      posts: allStoryblokEntry(
        filter: { full_slug: { regex: "/events//" } }
        sort: { field_date_string: ASC }
      ) {
        edges {
          node {
            uuid
            fullSlug: full_slug
            lang
            date: field_date_string
            title: field_title_string
            image: field_image_string
            excerpt: field_excerpt_string
            content
          }
        }
      }
      events: allStoryblokEntry(
        filter: { full_slug: { regex: "/event//" } }
        sort: { field_date_string: ASC }
      ) {
        edges {
          node {
            uuid
            fullSlug: full_slug
            lang
            date: field_date_string
            place: field_mainLocation_string
            title: field_title_string
            image: field_image_string
          }
        }
      }
    }
  `);

  const posts = filterStoryblokCollectionByLanguage<PostSummary>(
    language,
    result.posts.edges,
  );

  const parsedPosts = Object.keys(posts).map((k) => {
    const parsedContent = JSON.parse(posts[k].content);

    posts[k].extLink = parsedContent.ext_link ?? null;
    posts[k].fullSlug = posts[k].fullSlug.toLowerCase();

    return posts[k];
  });

  const events = filterStoryblokCollectionByLanguage<EventPage>(
    language,
    result.events.edges,
  );

  const parsedEvents = Object.keys(events).map((k) => {
    events[k].fullSlug = events[k].fullSlug.toLowerCase();

    return events[k];
  });

  return [...parsedPosts, ...parsedEvents];
};

export const sortAsc = (a, b) => {
  if (dayjs(a.date).isBefore(dayjs(b.date))) return -1;
  if (dayjs(a.date).isAfter(dayjs(b.date))) return 1;

  return 0;
};

export const sortDesc = (a, b) => {
  if (dayjs(a.date).isBefore(dayjs(b.date))) return 1;
  if (dayjs(a.date).isAfter(dayjs(b.date))) return -1;

  return 0;
};
