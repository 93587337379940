import React, { FC } from "react";
import { useBlogCategories } from "@app/hooks";

export interface CategoryLabelProps {
  categoryId: string;
}

export const CategoryLabel: FC<CategoryLabelProps> = ({ categoryId }) => {
  const categories = useBlogCategories();
  const category = categories.filter(
    (category) => category.uuid === categoryId,
  )[0];

  return (
    <div
      className={
        "inline rounded-[3px] bg-dark p-2 text-small text-neutral-content"
      }
    >
      {category?.name}
    </div>
  );
};
