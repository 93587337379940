import React, { FC, ReactNode } from "react";
import { GatsbyLinkProps } from "gatsby";
import { Link as I18nLink, useI18next } from "gatsby-plugin-react-i18next";
import { useViewVariant } from "@app/hooks/use-view-variant/useViewVariant";
import {
  handleLinkClick,
  handleStrippedLinkClick,
} from "@app/utils/anchor-scroll";
import { addTrailingSlash, hasQueryParams } from "@app/utils";

export interface LinkProps extends GatsbyLinkProps<unknown> {
  language?: string;
  to: string;
  title?: string;
  className?: string;
  stripHash?: boolean;
  onAnchorLinkClick?: () => void;
  children?: ReactNode;
}

export const Link: FC<LinkProps> = ({
  to: _to,
  children,
  stripHash = true,
  onAnchorLinkClick,
  ...rest
}) => {
  let to =
    _to.includes("#") || _to.startsWith("http") ? _to : addTrailingSlash(_to);
  const { languages, language, defaultLanguage, originalPath } = useI18next();
  const langPrefix = languages.find((l) => to.startsWith(`/${l}/`));
  const { isContentOnly } = useViewVariant();

  // if it starts with a language prefix, remove it
  if (langPrefix) {
    to = to.replace(`/${langPrefix}`, "");
  }

  // External link
  if (to.startsWith("http")) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    );
  }

  // Non-hash link
  if (!to.includes("#")) {
    if (isContentOnly) {
      to = `${to}${hasQueryParams(to) ? "&" : "?"}content_only=true`;
    }

    return (
      <I18nLink to={to.toLowerCase()} {...rest}>
        {children}
      </I18nLink>
    );
  }

  if (to === "#") {
    return <a>{children}</a>;
  }

  if (to.includes("#") && !to.startsWith("/")) {
    to = `${originalPath.replace(/\/$/, "")}/${to}`;
  }

  let isSamePage = false;
  const isBrowser = typeof window !== "undefined";

  if (isBrowser) {
    const currentLocationWithoutLangPrefix =
      window.location.pathname.startsWith(`/${language}`) &&
      language !== defaultLanguage
        ? window.location.pathname.split(`/${language}`)[1]
        : window.location.pathname;

    isSamePage = currentLocationWithoutLangPrefix === to.split("#")[0];
  }

  // hash link
  const onClickHandler = stripHash ? handleStrippedLinkClick : handleLinkClick;
  const linkProps = {
    ...rest,
    /**
     * Spread optional gatsbyLinkProps object in fist, so our specific props will override
     */
    to: to,
    onClick: (e) => onClickHandler(to, e, isSamePage, onAnchorLinkClick),
  };

  return <I18nLink {...linkProps}>{children}</I18nLink>;
};
