"use client";

import React, { createContext } from "react";
import { getQueryParams } from "react-use-query-param-string";

interface IViewVariant {
  isContentOnly: boolean;
}

export const ViewVariantContext = createContext({} as IViewVariant);

export const ViewVariantProvider = ({ children }: React.PropsWithChildren) => {
  const queryParams = getQueryParams();
  const isContentOnly = queryParams.content_only === "true";

  return (
    <ViewVariantContext.Provider
      value={{
        isContentOnly: isContentOnly,
      }}
    >
      {children}
    </ViewVariantContext.Provider>
  );
};
